import { EnvironmentConstants } from "../constants/enviromentConstants";
import Cookies from 'js-cookie';
import CryptoJS from "crypto-js";

export class Cookie {
    private sessionName = EnvironmentConstants.COOKIE_SESSION;

    public isCookieSessionActive(){ 
        if (Cookies.get(this.sessionName)) {
            let decryptedValue = CryptoJS.AES.decrypt(Cookies.get(this.sessionName), this.sessionName).toString(CryptoJS.enc.Utf8);
            return decryptedValue;
        }
    }

    public setSessionCookie(data: any){
        let encryptedData = CryptoJS.AES.encrypt(data, this.sessionName).toString()
        Cookies.set(this.sessionName, encryptedData, { secure: true });
    }

    public removeSessionCookie(){
        if (Cookies.get(this.sessionName)) Cookies.remove(this.sessionName)
    }
}

export const cookie = new Cookie();